// --- fonts   ---

@import url("https://fonts.googleapis.com/css2?family=Readex+Pro:wght@200;300;400;500;600;700&display=swap");
$font-Family: "Readex Pro", sans-serif;

// --- maps font weights ---

$font_weights: (
  "extra-light": 200,
  "light": 300,
  "regular": 400,
  "medium": 500,
  "semibold": 600,
  "bold": 700,
);

// --- maps font size ---
$font_size: (
  "xxs": 0.7em,
  "xs": 0.8em,
  "s": 1em,
  "m": 2em,
  "l": 2.5em,
  "xl": 3em,
  "xxl": 3.5em,
);
