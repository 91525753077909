/* import what we need to override */

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

/* set the overriding variables */

$grid-breakpoints: ( xxxs: 0, xxs: 320px, xs: 568px, sm: 667px, md: 768px, lg: 992px, xl: 1200px, xxl: 1440px, xxxl: 1600px, );
$container-max-widths: ( xxxs: 0, xxs: 320px, xs: 568px, sm: 667px, md: 768px, lg: 992px, xl: 1200px, xxl: 1440px, xxxl: 1600px, );

/* override the !default vars with the values we set above */

@import "bootstrap";

/* You can add global styles to this file, and also import other style files */

:root {
    --colorToolbar: #81a0c4;
    --colorSubtoolbar: #99d3df;
    --colorBtn: #30415d;
    --colorBtn2: #8eaebd;
    --colorBtn2Hover: #c5a974;
    --colorTexto: #ffffff;
    --colorTienePedidos: #6ccf4d;
    --colorRechazo: rgb(248, 209, 37);
    --colorBackground: #dae7f5;
}

html body {
    background-color: whitesmoke;
    min-height: 100vh;
}

.fondo {
    background: linear-gradient(#81a0c4, #546289);
    min-height: 100vh;
    width: 100%;
    padding: 0px;
    padding-bottom: 3rem;
    margin: 0px;
    overflow: hidden;
}

#pages {
    background-color: whitesmoke !important;
    color: black !important;
}

.alert-pcpos {
    background-color: #546289;
    color: #fff;
}

.text-pcpos {
    color: #546289;
}

.btn-pcpos {
    background-color: #4459A3;
    color: white;
    min-width: 75px !important;
}

.btn-pcpos:hover {
    background-color: #7398FF;
    min-width: 75px !important;
}

.btn-pcposalt {
    background-color: white;
    color: #7398FF;
    min-width: 75px !important;
}

.btn-pcposalt:hover {
    background-color: #dae7f5;
    color: black;
    min-width: 75px !important;
}

.pointer {
    cursor: pointer;
}

.bg-login {
    background-color: var(--colorBackground);
}

.bg-navegation {
    background-color: #4459A3;
}

.boxShadow {
    box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 1px 8px 3px rgba(0, 0, 0, 0.3);
}

.fondo-modal-imagen{
    background-color: rgba(0, 0, 0, 0.7);
    left:0px;
    height: 100%;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999999999;

}

.oculto{
    display: none;
}
