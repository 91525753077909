// --- variables ---
@use "../sass/abstracts/" as abs;
html {
  font-family: abs.$font-Family;
  overflow-y: hidden;
}

body {
  overflow-y: hidden;
  font-family: abs.$font-Family;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background: {
    image: url("../assets/design/background.png");
    size: cover;
    position: center;
    repeat: no-repeat;
  }
}

*::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
  background: abs.color(
    abs.$greyColor,
    "base"
  ); /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background: abs.color(abs.$greyColor, "base"); /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  background: abs.color(
    abs.$greyColor,
    "secondary"
  ); /* creates padding around scroll thumb */
}

// ::-webkit-scrollbar  {
//     width:12px;
//     color:red;
// }
* {
  list-style: none;
}

img {
  margin: 0;
}

a {
  text-decoration: none;
}

*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

/*----------card init ----------*/

.containerN {
  @include abs.flex(center, center, row, wrap);
  height: 100vh;
  background-color: none;
}

.cardN {
  width: 100%;
  // max-width: 58em;
  display: flex;
  height: auto;
  @include abs.boxShadow();
  @include abs.borderR();
  background-color: none;
}

#cardNInst {
  width: auto;
  margin-top: 20em;
}

.cardN-modal {
  width: auto;
  display: flex;
  height: auto;
  @include abs.boxShadow();
  @include abs.borderR();
  background-color: none;
}

/*---------- colum left init----------*/

.card__columnL {
  background-color: abs.color(abs.$primaryColor, "base");
  height: auto;
  @include abs.flex(space-between, center, column, wrap);
  @include abs.borderL();
  width: 10em;
  max-width: 5em;
  min-width: 5em;
}

.card__logoNuvalidC,
.card__phoneC {
  margin: 1em 0px 1em 0px;
}

/*---------- colum left end----------*/

/*---------- colum right init----------*/

.card__columnR {
  background-color: abs.color(abs.$secondaryColor, "transparency");
  @include abs.borderR();
  padding: 4em 4em 0 4em;
  // padding-top: 2em;
}

.form-textN {
  font-weight: abs.weight("light");
  font-size: abs.size("s");
  margin-bottom: 1em;
}

.form-group {
  margin-bottom: 0;
}

.form-text__bold {
  font-weight: abs.weight("bold");
  font-size: abs.size("s");
}

.form-infoN {
  @include abs.flex(flex-start, center, row, nowrap);
  width: 100%;
  gap: 0.5em;
  height: abs.size-button("s");
  box-sizing: border-box;
  white-space: nowrap;
  .form-controlN {
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 0.7em;
    -webkit-border-radius: 0.7em;
    -moz-border-radius: 0.7em;
    -ms-border-radius: 0.7em;
    -o-border-radius: 0.7em;
    width: 90%;
    border: none;
    position: relative;
    padding: 10px;
    font-size: abs.size("s");
    &:focus {
      outline: none;
      box-shadow: 0 0 6px 0 abs.color(abs.$primaryColor, "base");
    }
    &::placeholder {
      color: abs.color(abs.$greyColor, "text");
      // font-size: abs.size("s");
    }
  }
  .form-controlNError {
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 0.7em;
    -webkit-border-radius: 0.7em;
    -moz-border-radius: 0.7em;
    -ms-border-radius: 0.7em;
    -o-border-radius: 0.7em;
    width: 90%;
    border: none;
    position: relative;
    padding: 10px;
    font-size: abs.size("s");
    &:focus {
      outline: none;
      box-shadow: 0 0 6px 0 abs.color(abs.$alertColor, "base");
    }
    &::placeholder {
      color: abs.color(abs.$greyColor, "text");
      // font-size: abs.size("s");
    }
  }
  label {
    font-weight: abs.weight("light");
    font-size: abs.size("s");
  }
  // input {
  //     font-size: abs.size("xs");
  //     font-weight: abs.weight("light");
  // }
}

/*---------- colum right end----------*/

//---------------------Instalacion init
.reminder {
  @include abs.flex(flex-start, center, row, nowrap);
  height: auto;
  gap: 0.5em;
  margin-top: 1em;
  margin-left: 2em;
}

.reminderSugerencia {
  @include abs.flex(flex-start, center, row, nowrap);
  height: auto;
  margin-left: 5em;
  margin-bottom: 1em;
}

.reminderArchivo {
  @include abs.flex(flex-start, center, row, nowrap);
  height: auto;
  gap: 0.5em;
}

.reminder__text {
  width: 50em;
  font-weight: abs.weight("light");
  color: abs.color(abs.$greyColor, "secondary");

  font-size: abs.size("xs");
  margin-bottom: 0;
  margin-left: 1em;
}

.reminder__Archivo {
  width: 50em;
  font-weight: abs.weight("light");
  color: abs.color(abs.$greyColor, "secondary");

  font-size: abs.size("xs");
  margin-bottom: 0;
}

.reminder__textCrear {
  font-weight: abs.weight("light");
  color: abs.color(abs.$greyColor, "secondary");
  font-size: abs.size("xs");
  margin-bottom: 0;
  margin-left: 1em;
}

#reminder__textCrear {
  width: auto;
}

.reminder__warning {
  margin-bottom: 1em;
}

.reminder__link {
  color: abs.color(abs.$primaryColor, "base");
  cursor: pointer;
}

.components {
  margin: 0px 2em 0px 2em;
  @include abs.flex(space-between, center, row, nowrap);
  .components__img {
    bottom: 0;
    margin-top: 2.7em;
    width: 40%;
    height: auto;
  }
  .botton {
    background-color: abs.color(abs.$primaryColor, "base");
    color: white;
    border: none;
    border-radius: 5px;
    width: 7em;
    height: abs.size-button("s");
    font-size: 15px;
    padding: 0.3em;
    align-self: center;
    margin-right: 0;
    transition: border-radius 0.5s;
    -webkit-transition: border-radius 0.5s;
    -moz-transition: border-radius 0.5s;
    -ms-transition: border-radius 0.5s;
    -o-transition: border-radius 0.5s;
    &:hover {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      background-color: abs.color(abs.$primaryColor, "hover");
    }
    &:active {
      background-color: abs.color(abs.$primaryColor, "active");
      transform: translateY(1px);
    }
  }
  .bottonDanger {
    //  background-color: abs.color(abs.$primaryColor, "base");
    background-color: abs.color(abs.$alertColor, "base");
    color: white;
    border: none;
    border-radius: 5px;
    width: 7em;
    height: abs.size-button("s");
    font-size: 15px;
    padding: 0.3em;
    align-self: center;
    margin-right: 0;
    transition: border-radius 0.5s;
    -webkit-transition: border-radius 0.5s;
    -moz-transition: border-radius 0.5s;
    -ms-transition: border-radius 0.5s;
    -o-transition: border-radius 0.5s;
    &:hover {
      border-radius: 8px;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      background-color: abs.color(abs.$alertColor, "hover");
    }
    &:active {
      background-color: abs.color(abs.$alertColor, "active");
      transform: translateY(1px);
    }
  }

  .components__imgDoctora {
    bottom: 0;
    margin-top: 2.7em;
    // width: 40%;
    height: auto;
    margin-left: 2.5em;
  }
  #doctora {
    width: 50%;
    height: auto;
    z-index: 99999999;
    // margin-top: 5em;
    box-sizing: border-box;
    // margin: -8px;
    padding: 0;
    outline: none;
  }
}

//---------------------Instalacion end
//---------------------InstalacionError init
.reminder--error {
  background-color: abs.color(abs.$alertColor, "background");
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: auto;
  // padding: 0.1em;
  box-sizing: border-box;
  //  margin-top: 0.7em;
  // margin-left: 2em;
  margin-bottom: 0;
}

.reminder__text--error {
  padding: 0.3em -1.5em 0 0.1em;
  // padding-right: -1.5em;
  box-sizing: border-box;
  width: 110%;
  // color: abs.color(abs.$textColorD, "base");
  color: abs.color(abs.$alertColor, "base");
}

#reminder__link--error {
  // color: abs.color(abs.$textColorD, "base");
  color: abs.color(abs.$alertColor, "base");
}

// #containerAlert__img {
//     margin-top: 0.7em;
// }

//---------------------InstalacionError end
//---------------------Modal-error init
#modal-error {
  height: abs.height-cards("xxs");
}

.modal-text {
  font-weight: abs.weight("regular");
}

.botton-modal {
  margin-top: 4em;
  position: relative;
  right: -3em;
}

.ilustrations-modal {
  @include abs.flex(flex-end, center, row, nowrap);
}

.form-errorN {
  @include abs.flex(space-between, center, colum, nowrap);
}

.form-error__image {
  position: relative;
  top: -2.5em;
  right: -6m;
}

//---------------------Modal-error end
//---------------------Modal-errorInstalacion init
.form-dataN {
  @include abs.flex(space-between, center, row, nowrap);
  margin-top: -3em;
}

.form-contactN {
  @include abs.flex(base-line, center, row, nowrap);
  margin: 0;
  padding: 0;
  p {
    padding: 0;
    margin: 0;
    width: 20em;
  }
  figure {
    margin: 0;
    margin-right: 1em;
  }
  height: 2em;
}

.form-contact__text {
  color: abs.color(abs.$greyColor, "textOne");
  font-weight: abs.weight("light");
}

.form-contact__link {
  color: abs.color(abs.$primaryColor, "base");
  &:hover {
    color: abs.color(abs.$primaryColor, "secondary");
  }
  cursor: pointer;
}

#card__columnR--modal {
  // padding: 3em 2em 1em 2em;
  background-color: abs.color(abs.$secondaryColor, "base");
  // background-color: white;
}

#column__modalInstalacion {
  @include abs.flex(center, center, row, nowrap);
  width: abs.width-cards("xxs");
}

#modal-errorInstalacion {
  height: abs.height-cards("xs");
}

.form-info--modal {
  position: relative;
  top: 1em;
}

.form-info__text {
  color: abs.color(abs.$greyColor, "text");
}

.ilustrations-modal {
  margin-left: 0;
}

#ilustrations-contactWoman {
  width: 18em;
  height: auto;
  padding: 0;
  margin: 0 auto;
}

#form-text--modal {
  font-size: abs.size("s");
}

.form-errorI__image {
  position: relative;
  top: -2.5em;
  right: -2em;
}

//---------------------Modal-errorInstalacion end
//---------------------Modal-contact init
.form-contact__image {
  position: relative;
  top: -2em;
  left: 45em;
}

#modal-contact {
  height: abs.height-cards("xxs");
}

.form-data__contact {
  position: absolute;
}

//---------------------Modal-contact end
//---------------------Select direction init
.container-direction {
  height: auto;
}

.container__list-directions {
  @include abs.flex(center, center, column, nowrap);
  height: auto;
}

.list-directions {
  @include abs.flex(center, center, column, nowrap);
  display: block;
  height: auto;
  overflow-x: hidden;
  // max-height: abs.height-cards("xxxs");
  width: 100%;
  li {
    &:nth-child(even) {
      background-color: abs.color(abs.$greyColor, "direction");
    }
    &:nth-child(odd) {
      background-color: abs.color(abs.$greyColor, "directionOne");
    }
  }
  padding: 0;
  margin: 0;
  overflow-y: auto;
  background-color: abs.color(abs.$greyColor, "directionOne");
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  text-align: center;
}

.list-directions-prestadores {
  @include abs.flex(center, center, column, nowrap);
  display: block;
  height: auto;
  overflow-x: hidden;
  // max-height: abs.height-cards("xxxs");
  width: 100%;
  li {
    &:nth-child(even) {
      background-color: abs.color(abs.$greyColor, "direction");
    }
    &:nth-child(odd) {
      background-color: abs.color(abs.$greyColor, "directionOne");
    }
  }
  padding: 0;
  margin: 0;
  overflow-y: auto;
  background-color: abs.color(abs.$greyColor, "directionOne");
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  text-align: center;
  max-height: 20em;
  overflow: auto;
}

.label-directions {
  font-size: 0.8em;
  color: abs.color(abs.$textColorD, "secondary");
  font-weight: abs.weight("light");
}
.custom-radio {
  // .custom-radio-text {
  //     font-size: 1em;
  //     color: abs.color(abs.$textColorD, "base");
  //     font-weight: abs.weight("light");
  //     margin-left: 1em;
  // }
  height: 32px;
  @include abs.flex(flex-start, center, row, nowrap);
  padding-left: 35%;
}

.custom-radio label input[type="radio"] ~ i.fa.fa-circle-o {
  color: abs.color(abs.$greyColor, "icons");
  display: inline;
}

.custom-radio label input[type="radio"] ~ i.fa.fa-dot-circle-o {
  display: none;
}

.custom-radio label input[type="radio"]:checked ~ i.fa.fa-circle-o {
  display: none;
}

.custom-radio label input[type="radio"]:checked ~ i.fa.fa-dot-circle-o {
  color: abs.color(abs.$primaryColor, "base");
  display: inline;
}

.custom-radio label:hover input[type="radio"] ~ i.fa {
  color: abs.color(abs.$primaryColor, "base");
}

.custom-radio label input {
  display: none;
}

.card__columnR--direction {
  // padding: 40px 64px 32px 64px;
  padding: 4em;
  padding-bottom: 2em;
}

.reminder-direction {
  margin: 0;
  margin-left: 1em;
  margin-top: 1em;
}

.reminder__warningDirections {
  margin-bottom: 0;
}

.reminder__textDirections {
  margin-left: 0;
}

.components-directions {
  margin-top: 1em;
  @include abs.flex(center, center, row, nowrap);
}

//------------MODAL PRESTADORES

.cardN-modal {
  width: auto;
}
.card__columnL-modal {
  justify-content: center;
  border-radius: 0;
  width: max-content;
}

.card__columnR--modal {
  width: 100%;
  padding: 4em;
}

.container__list-directions-modal {
  align-items: flex-start;
}

.list-directions-modal {
  width: 100%;
}

.reminder__text-modal {
  width: auto;
}

.reminder__warning-modal {
  margin-bottom: 0;
}

.reminder-modal {
  margin-bottom: 1em;
  margin-left: 0;
}

.components-modal {
  margin: 0;
}
//------------------------Login
//-----------Restablecer contraseña
#components-loginPRestablecer {
  width: 100%;
}

#docPassword {
  margin-left: 3em;
}

.text__passwordRestablecer {
  margin: 2em 0 0 5em;
}

.reminder-passwordCRestablecer {
  width: 120%;
}

.reminder-passwordRestablecer {
  margin-top: 1em;
}

#cardNRestablecer {
  width: auto;
}

.containerNNav {
  @include abs.flex(none, center, column, wrap);
  height: 100vh;
  background-color: none;
  overflow: auto;
}
.cardN-loginP {
  // height: 43em;
  margin-left: 1em;
  width: 600px;
  margin-top: 10em;
}

.reminder__warning-login {
  margin: 0;
}

.labelForm {
  margin-bottom: 0 !important;
}

#label-email::before {
  background-image: url("../assets/design/emailG.svg");
  margin-bottom: 0;
  background-position: center;
  background-repeat: no-repeat;
}

/*---------- colum right end----------*/

//------------------------Login
#cardN-loginP {
  height: 50em;
  margin-left: 1em;
  width: 600px;
}

.cardN-login {
  // height: 95%;
  height: 50em;
  // max-height: 50em;
  width: 60%;
  max-width: 35em;
}

.cardN-register {
  height: 95%;
  max-height: 50em;
  width: 60%;
  max-width: 35em;
}

#card__columnL-login {
  width: 100px;
}

#card__columnR-login {
  position: relative;
  padding: 50px;
  padding-left: 10px;
  overflow: auto;
  width: 100%;
}

.illustrations {
  margin: 0;
  .img__login {
    position: absolute;
    top: 50px;
    left: 100px;
    width: 50%;
    height: auto;
  }
}

#button-box {
  width: 200px;
  margin: 0 auto;
  position: relative;
  top: 250px;
  // transition: .5s;
  border-bottom: 2px solid abs.color(abs.$greyColor, "base");
  #btn {
    top: 0;
    position: absolute;
    width: 105px;
    height: 100%;
    // transition: .5s;
    border-bottom: 2px solid abs.color(abs.$primaryColor, "base");
  }
}

#btnentrar {
  margin-left: 50px !important;
}

.buttonSelected {
  color: abs.color(abs.$textColorD, "base");
}

.buttonUnselected {
  color: abs.color(abs.$greyColor, "secondary");
}

.btnSelectedLogin {
  left: 0px;
  transition-property: left;
  transition-duration: 1s;
}

.btnSelectedRegister {
  left: 110px;
  transition: left 1s;
  // transition-property: left;
  // transition-duration: 1s;
}

.groupSelected {
  @include abs.flex(center, center, column, nowrap);
}

.groupUnselected {
  display: none !important;
}

.toggle-btn {
  padding: 10px 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: none;
  position: absolute;
  font-size: abs.size("xs");
  font-weight: abs.weight("medium");
}

#button-register {
  margin-left: 100px;
}

// #login {
//     left: 50px;
// }
// #register {
//     left: 550px;
// }
.input-group {
  top: 350px;
  position: absolute;
  width: 280px;
  transition: 0.5s;
  // @include abs.flex(center, center, column, nowrap);
  margin-left: 50px;
}

.input-group-login {
  top: 350px;
  position: absolute;
  width: 280px;
  transition: 0.5s;
  // @include abs.flex(center, center, column, nowrap);
  margin-left: 50px;
}

.input-group-agenda {
  // top: 350px;
  // position: absolute;
  display: flex;
  width: 280px;
  transition: 0.5s;
  // @include abs.flex(center, center, column, nowrap);
  margin-left: 50px;
}

.data-login label {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 10px;
    z-index: 10;
    width: 20px;
  }
}

.labelForm::before {
  background: {
    size: contain;
    position: center;
    repeat: no-repeat;
  }
}

#label-email::before {
  background-image: url("../assets/design/emailG.svg");
  margin-bottom: 0;
}

#label-password::before {
  background-image: url("../assets/design/password.svg");
}

// #label-datalist::before {
//     background-image: url("../assets/design/location.svg");
//     margin-bottom: 0;
// }
#label-name::before {
  background-image: url("../assets/design/name.svg");
}

#label-lastname::before {
  background-image: url("../assets/design/name.svg");
}

#label-phone::before {
  background-image: url("../assets/design/phoneG.svg");
}

// #label-datalist::after {
//     content: "";
//     background: url("../assets/design/arrowdown.svg");
//     background-size: contain;
//     background-repeat: no-repeat;
//     position: absolute;
//     height: 100%;
//     width: 15px;
//     right: 18px;
//     top: 5px;
//     transition: all 0.4s;
// }
#label-datalist {
  content: "";
  // background: url("../assets/design/location.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 15px;
  right: 18px;
  top: 5px;
  transition: all 0.4s;
}

.eye {
  color: #bababa;
  margin-top: 10px;
  top: 5px;
  position: inherit;
  left: -20px;
}

.data-login input,
.data-login select {
  margin: 0.5em;
  background-color: abs.color(abs.$greyColor, "base");
  border-radius: 0.7em;
  -webkit-border-radius: 0.7em;
  -moz-border-radius: 0.7em;
  -ms-border-radius: 0.7em;
  -o-border-radius: 0.7em;
  width: 90%;
  border: none;
  position: relative;
  padding: 10px;
  font-size: abs.size("s");
  &:focus {
    outline: none;
    box-shadow: 0 0 6px 0 abs.color(abs.$primaryColor, "base");
  }
  &::placeholder {
    color: abs.color(abs.$greyColor, "text");
  }
}

.data-login select {
  width: 22em;
  &:first {
    color: abs.color(abs.$greyColor, "secondary");
    font-family: abs.$font-Family;
    font-weight: 100;
  }
  option {
    color: abs.color(abs.$textColorD, "base");
  }
}

.data-login input {
  width: 22em;
  padding-left: 50px;
}

.password {
  position: absolute;
  top: 1px;
  right: 20px;
  cursor: pointer;
  &:hover {
    img {
      filter: invert(25%) sepia(31%) saturate(292%) hue-rotate(155deg)
        brightness(97%) contrast(97%);
    }
  }
}

.reminder-loginHelp {
  width: 20em;
  margin: 2em 0 1em 1em;
}

#hiden,
#hiden1,
#hiden2 {
  display: none;
  width: 19px;
}

#form-textNP {
  @include abs.flex(center, center, column, nowrap);
}

.reminder-loginPassword {
  margin: 0;
}

.reminder__link-login {
  color: abs.color(abs.$primaryColor, "base");
  font-weight: abs.weight("light");
  color: abs.color(abs.$greyColor, "secondary");
  font-size: abs.size("xs");
  text-decoration: underline;
  padding-left: 80px;
}

.textCookies {
  color: abs.color(abs.$greyColor, "secondary");
  font-size: abs.size("xxs");
  text-align: center;
  .textCookies-link {
    font-size: abs.size("s");
    color: abs.color(abs.$primaryColor, "base");
  }
}

.components-login {
  padding: 1.5em 0 1.5em 0;
  padding-bottom: 0;
  align-items: center;
}

#button-loginFinish {
  padding-bottom: 1em;
  margin-left: 0;
}

//------------------------password
#cardNP {
  width: auto;
  // width: 32em;
}

#card__columnLP {
  width: 100px;
}

#card__columnR-loginP {
  width: auto;
  height: auto;
  @include abs.flex(center, center, column, nowrap);
}

.img__login {
  width: 50%;
  height: auto;
}

.text__password {
  @include abs.flex(center, center, column, nowrap);
  .tittleP {
    font-size: abs.size("xs");
    font-weight: abs.weight("regular");
  }
}

#form-loginP {
  @include abs.flex(center, flex-start, column, nowrap);
  width: 80%;
}

.reminder-passwordC {
  width: 90%;
  .reminder-password {
    font-size: abs.size("xs");
    font-weight: abs.weight("regular");
    color: abs.color(abs.$greyColor, "secondary");
  }
  .lineP {
    border-bottom: 1px solid abs.color(abs.$primaryColor, "base");
    width: 100%;
  }
}

#data-loginP {
  margin-left: -10px;
  input {
    width: 90%;
  }
}

.components-loginP {
  margin: 1em 0 2em 0;
  align-self: center;
}

.reminder__loginE {
  // margin-left: 20em;
  width: auto;
  margin-left: 1em;
  margin-top: 0;
  @include abs.flex(flex-start, center, row, nowrap);
  height: auto;
  gap: 0.5em;

  .reminder__textLoginE {
    margin: 0;
    padding: 0;
    font-weight: abs.weight("light");
    // color: abs.color(abs.$greyColor, "secondary");
    color: abs.color(abs.$alertColor, "base");

    font-size: abs.size("xs");
    margin-bottom: 1em;
    .reminder__grey {
      font-weight: abs.weight("regular");
    }
  }
}

.form-controlN-error input {
  &:focus {
    outline: none;
    box-shadow: 0 0 6px 0 abs.color(abs.$alertColor, "base");
  }
}

.app-navbar-windows {
  width: 100vw;
}
//------------------------------DASHBOARD Registracion
.containerN-dashboard {
  // margin: 0em 2em 0em 2em;
  // max-height: 100vh;
  // height: 100%;
  height: 100vh;
}

.cardN-dashboard {
  width: 100vw;
  height: 100vh;
  // height: auto;
  display: flex;

  @include abs.boxShadow();
  @include abs.borderR();
  background-color: none;
}

.card__columnL-dashboard {
  width: 8em;
  border-radius: 0;
  // height: auto;
  height: 100%;
}

.card__columnL-up {
  @include abs.flex(flex-start, center, column, wrap);
  .card__columnL-upIcons {
    @include abs.flex(flex-start, center, column, wrap);
    margin-top: 0;
    img {
      margin: 1em 0 1em 0;
    }
  }
  .card__logoNuvalid-dashboard {
    margin-bottom: 3em;
  }
  .card__columnL-upIconsS {
    margin-top: 2em;
  }
}

.card__columnR-dashboard {
  border-radius: 0;
  background-color: transparent;
  padding: 1em;
  overflow: auto;
  overflow-x: hidden;
  // min-width: 1297px !important;
  // min-width: 100vw;
  width: 100%;
  // height: 100%;
  height: 100vh;
  padding-bottom: 5%;
}

.lineHDashboard {
  border-top: 1px solid abs.color(abs.$secondaryColor, "base");
  height: 1px;
  width: 50px;
  margin-top: 2em;
}

.container_iconsColumnLFarmacia{
  &:hover {
    background-color: abs.color(abs.$primaryColor, "hoverFarm");
    border-radius: 8px;
    width: 45px;
    height: auto;
    @include abs.flex(center, center, row, nowrap);
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "hoverFa");
  }
}

.container_iconsColumnL {
  &:hover {
    background-color: abs.color(abs.$primaryColor, "hoverB");
    border-radius: 8px;
    width: 45px;
    height: auto;
    @include abs.flex(center, center, row, nowrap);
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "hover");
  }
}

.iconSize {
  width: 25px;
}

.contianerDown {
  @include abs.flex(center, center, column, nowrap);
  margin-bottom: 2.5em;
}
.versionP {
  font-size: 1em;
  color: white;

  margin: 0;
  text-align: center;
}
//-----------------------MODIFICAR USUARIOS
.btnModificarUsuario {
  display: flex;
  justify-content: flex-end;
}

.btnEstiloNuvalidModificarA {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 7em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hoverB");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}

.btnEstiloNuvalidMedio {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 12em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hoverB");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}
//-----------------------PANEL INICIAL
//-----------------------Datos de usuario
.container__datosInit {
  max-width: 100vw;
  //margin-top: 1em;
  margin-top: 10px;
  background-color: white;
  // border-radius: 10px;
}

.container__dataUser {
  @include abs.flex(space-between, center, row, wrap);
  background-color: abs.color(abs.$secondaryColor, "background");
  // border-radius: 10px;
  width: 100%;
  // max-width: 1500px;
  padding-right: 1em;
  .dataUser__title {
    font-size: abs.size("m") - 0.8em;
    font-weight: abs.weight("semibold");
    margin-left: 1em;
    margin-bottom: 0;
    .dataUser__titleU {
      color: abs.color(abs.$primaryColor, "base");
    }
    .dataUser__titleUFarm {
      color: abs.color(abs.$primaryColor, "baseFarm");
    }
  }
  .dataUserR {
    @include abs.flex(center, center, row, wrap);
    margin-top: 1em;
    figure {
      margin-left: 0.8em;
      margin-right: 0.8em;
    }
    .DataUserR-user {
      margin-left: 2em;
    }
  }
}

//-------------------ESTILOS GENERALES
.tittle__dashboard {
  font-size: abs.size("s");
  font-weight: abs.weight("semibold");
  margin: 1em;
}

.span-required {
  color: abs.color(abs.$alertColor, "base");
}

//-----------------------PANEL 1
.container__panel1 {
  max-width: 100%;
  // margin-top: 1em;
  background-color: transparent;
  //----------------------Financiador
  .container__financiador {
    background-color: abs.color(abs.$secondaryColor, "background");
    border-radius: 20px 20px 0 0;
    height: auto;
    max-height: 400px;
    // padding: 0.5em 0em 0 1em;
    padding: 0 0em 0 1em;
    .title_containerF {
      @include abs.flex(space-between, center, row, nowrap);
      .arrowDownPanel {
        margin-right: 2em;
      }
    }
    .containerFinanciers-img {
      @include abs.flex(flex-start, flex-start, row, wrap);
      height: 18em;
      overflow-y: scroll;
      gap: 0.8em;
      width: 100%;
      .financier {
        background-color: abs.color(abs.$greyColor, "base");
        width: 17em;
        height: 8em;
        margin: 0;
        border-radius: 10px;
        cursor: pointer;
        @include abs.flex(center, center, row, nowrap);
        img {
          width: 16rem;
          height: auto;
        }
      }
    }
  }
  .financier-notChecked {
    display: none;
  }
  .financier-checked {
    position: relative;
    top: -2.8em;
    left: -1.2em;
  }
  .financierSelected {
    box-sizing: border-box;
    border: 3px solid abs.color(abs.$sucessColor, "base");
  }
  //----------Afiliado
  .container__afiliado {
    background-color: abs.color(abs.$secondaryColor, "background");
    padding: 0.5em 0em 1em 1em;
    // margin-top: 1em;
    .containerInputA {
      width: 80%;
      margin-left: 3em;
      label {
        margin-right: 1em;
      }
      .labelAgenda {
        margin-right: 2.8em !important;
      }
      #input-credencial {
        width: 80%;
        height: 2em;
      }
    }
  }

  .reminder_afiliado {
    margin-left: 15em;
    margin-top: 0.2em;
  }
  .reminder__textAfiliado {
    width: fit-content;
    margin-left: 0;
    margin-top: 0.5em;
  }

  .reminder__textAlertAfiliado {
    margin-left: 0;
  }
  //----------Prestador
  .container__prestador {
    // margin-top: 1em;
    background-color: abs.color(abs.$secondaryColor, "background");
    border-radius: 0 0 20px 20px;
    padding: 0 0 0 1em;
    @include abs.flex(space-between, center, row, wrap);
    .prestador-columnR {
      @include abs.flex(center, flex-start, column, wrap);
      .prestador-form {
        @include abs.flex(center, center, row, wrap);
        gap: 1em;
        #optionPrestadores {
          width: 35em;
          height: 2.5em;
          outline: none;
          background-color: abs.color(abs.$greyColor, "base");
          border-radius: 5px;
          border: 0;
          font-size: abs.size("xs");
          padding-left: 1em;
          &:focus {
            outline: none;
          }
        }
      }
      .reminderPrestador {
        width: 80%;
        margin-left: 5em;
        margin-bottom: 1em;
      }
      .reminder__warningPrestador {
        margin: 0;
        margin-left: 10.5em;
      }
      .reminder__textPrestador {
        // margin-top: 0;
        width: 60%;
        // margin-bottom: 0;
        margin: 0;
        .reminder__PrestadorB {
          font-weight: abs.weight("semibold");
        }
      }
    }
    .illustrations-prestador {
      margin-bottom: 0em;
      margin-left: 2em;
      img {
        margin-bottom: -0.3em;
      }
    }
  }
}

//---------------------ERROR INPUT AFILIADO
.reminder__alert {
  // margin-left: 12em;
  margin-left: 15em;
  margin-top: 0.2em;
}

.reminder__alertA {
  // margin-left: 13%;
  margin-left: 15em;
  margin-top: 0.2em;
}

.reminder__textAlert {
  width: auto;
  // background-color: abs.color(abs.$alertColor, "background");
  // border-radius: 10px;
  // padding: 0em 1em 0.8em 1em;
  color: abs.color(abs.$alertColor, "base");
}

// .reminder__warningAfiliado {
//     margin-left: 3em;
// }

// .reminder__warningAfiliadoAgenda {
//     margin-left: 1em;
// }

//----------------DASHBOARD Operacion
//-----------------------PANEL 1
//---------------financiador
.tittle_containerF-collapsed {
  @include abs.flex(space-between, center, row, wrap);
  margin-bottom: 1em;
}

.container__financiador-collapsed {
  // border-radius: 10px;
  background-color: abs.color(abs.$secondaryColor, "background");
  height: auto;
  max-height: 400px;
  padding: 0.5em 0em 0 1em;
}

.container_infoFinanciador {
  @include abs.flex(flex-start, center, row, wrap);
  font-weight: abs.weight("semibold");
  .tittle_dataFinanciador {
    margin-right: 1em;
  }
  .data_infoFinanciador {
    font-weight: abs.weight("regular");
  }
}

.iconsPanel_financiador {
  @include abs.flex(space-between, space-between, row, nowrap);
  width: 5em;
  margin-right: 2em;
}

//-----------------------PANEL 2
//------------------------Operaciones

.aceptarRechazarOperacion {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.container__operacionGeneral {
  background-color: transparent;
  border-radius: 10px;
  margin-top: 1em;
  margin-left: -1em;
  height: 100%;
  width: 100%;
}

// #operaciones{
//     height: 100vh;
// }
.iconsPanel_operacion {
  @include abs.flex(space-between, center, column, wrap);
  width: 100%;
  // margin-right: 2em;
}

.tittle__operacion {
  padding: 1em 0 1em 1em;
}

.containerNOperacion {
  width: 100%;
  background: white;
  margin-top: 1em;
  //   border-radius: 10px;
}
.container__operacionesOneRow {
  @include abs.flex(space-between, space-between, column, nowrap);
  height: 50%;
  // width: 80%;
  padding-left: 1.7em;
}

.container__infoDiagOneRow {
  @include abs.flex(space-between, space-between, column, nowrap);
  height: 50%;
  // width: 80%;
}

.container__operacionesMoreRow {
  @include abs.flex(space-between, space-between, row, wrap);
  height: 50%;
}

// .container__operaciones {
//     @include abs.flex(center,
//     center,
//     column,
//     wrap);
// }
.container__dataOperaciones {
  // @include abs.flex(flex-start,
  // center,
  // row,
  // wrap);
  margin-left: 1em;
  margin-right: 1em;
  // width: 90vw;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  gap: 0.5em;
  // grid-auto-rows: 5em;
  // @include abs.flex(flex-start,flex-start,column,wrap);
  .operacion {
    width: auto;
    height: 2em;
    white-space: nowrap;
    padding: 0 1em 0 1em;
    border-radius: 10px;
    margin-bottom: 0.2em;
    cursor: pointer;
    // width: fit-content;
    // height: fit-content;
    // padding: 0 1em 0 1em;
    // border-radius: 10px;
    // margin-bottom: 0.2em;
    // cursor: pointer;
    // p:hover {
    //     color: abs.color(abs.$primaryColor, "base");
    // }
  }
}

.operacionNormal {
  //background-color: abs.color(abs.$primaryColor, "buttonO");
  border: 2px solid abs.color(abs.$primaryColor, "buttonOText");
  box-sizing: border-box;
  .operacion__text {
    font-family: abs.$font-Family;
    color: abs.color(abs.$primaryColor, "buttonOText");
    &:hover {
      color: abs.color(abs.$primaryColor, "base");
    }
  }
}

.operacionNormalHija {
  //background-color: abs.color(abs.$primaryColor, "buttonO");
  box-sizing: border-box;
  .operacion__text {
    font-family: abs.$font-Family;
    color: abs.color(abs.$primaryColor, "buttonOText");
    padding-top: 0.2em;
    // margin-bottom: 0.5em;
    text-transform: capitalize;
    text-align: center;
    &:hover {
      color: abs.color(abs.$primaryColor, "base");
    }
  }
}
.operacionSelected {
  box-sizing: border-box;
  background-color: abs.color(abs.$primaryColor, "buttonOText");
  color: white;
  .operacionNormalHija .operacion__text {
    font-family: abs.$font-Family;
    color: abs.color(abs.$secondaryColor, "base");
    padding-top: 0.2em;
    // margin-bottom: 0.5em;
    text-transform: capitalize;
    text-align: center;
    &:hover {
      color: abs.color(abs.$secondaryColor, "base");
    }
  }
}

// .operacionSelected{
//   box-sizing: border-box;
// //   @include abs.flex(flex-start,center,row,wrap);
//   background-color: abs.color(abs.$primaryColor,"buttonOText");
//   border: 5px solid abs.color(abs.$primaryColor,"buttonOText");
//   .operacion__text{
//   color: abs.color(abs.$secondaryColor,"base");
//   // color: white;
//   &:hover{
//       color: abs.color(abs.$secondaryColor,"base");
//     }
//   }
// }

.operacionImportant {
  //background-color: abs.color(abs.$primaryColor, "buttonO");
  border: 5px solid abs.color(abs.$primaryColor, "buttonOText");
  box-sizing: border-box;
  .operacion__text {
    font-family: abs.$font-Family;
    color: abs.color(abs.$primaryColor, "buttonOText");
    &:hover {
      color: abs.color(abs.$primaryColor, "base");
    }
  }
}

// .operacionSelected {
//     box-sizing: border-box;
//     @include abs.flex(flex-start,
//     center,
//     row,
//     wrap);
//     background-color: abs.color(abs.$primaryColor, "buttonOText");
//     border: 5px solid abs.color(abs.$primaryColor, "buttonOText");
//     .operacion__text {
//         font-family: abs.$font-Family;
//         color: abs.color(abs.$primaryColor, "base");
//         &:hover {
//             color: abs.color(abs.$primaryColor, "base");
//         }
//     }
// }

// #textNormal {
//     font-family: abs.$font-Family;
//     color: abs.color(abs.$primaryColor, "buttonOText");
// }
// #textNormal:hover {
//     color: #3662ff;
// }
// #plus {
//     height: 40px;
// }
.operacionMore {
  background-color: abs.color(abs.$alertColor, "backgroundButton");
  box-sizing: border-box;
  @include abs.flex(center, center, row, nowrap);
  gap: 0.3em;
  margin: 0 !important;
  &:active {
    background-color: abs.color(abs.$alertColor, "hover");
  }
  .operacion__text {
    color: abs.color(abs.$alertColor, "secondary");
    // padding-top: 1em;
    margin: 0;
    &:hover {
      // color: abs.color(abs.$alertColor,"base");
      color: white;
    }
  }
}

.chechekdOperacion {
  position: relative;
  top: -0.5em;
  left: 0.8em;
}

.uncheckededOperacion {
  display: none;
}

// .data__verificadorAfiliado {
//     background-color: abs.color(abs.$alertColor, "secondary");
//     color: abs.color(abs.$alertColor, "base");
//     position: absolute;
//     // top: 650px;
//     padding: 1em;
//     border-radius: 10px;
//     font-size: abs.size("xxs");
//     // display: none;
//     visibility: hidden;
//     opacity: 0;
//     transition: all 500ms ease-out;
//     &::after {
//         content: "";
//         display: block;
//         position: absolute;
//         top: -18px;
//         right: 40px;
//         border-top: 10px solid transparent;
//         border-right: 10px solid transparent;
//         border-left: 10px solid transparent;
//         border-bottom: 10px solid abs.color(abs.$alertColor, "secondary");
//     }
// }
// #verificarAfiliado:hover>.data__verificadorAfiliado {
//     opacity: 1;
//     visibility: visible;
// }
.container__ilustrationOperation {
  margin: 0 auto;
  margin-top: 3em;
}

.container__ilustrationInfoDiag {
  margin: 0 auto;
  margin-top: 8em;
  margin-bottom: 3em;
}

//-------------------------Seleccion mas operaciones
.container__operacionGeneralMore {
  height: 100%;
  width: 100%;
  @include abs.flex(center, center, column, nowrap);
}

#card__columnRMore {
  width: 100%;
}

.tittle__operacionMore {
  text-align: center;
  margin: 0 auto;
  // padding-top: 2em;
  box-sizing: border-box;
  white-space: nowrap;
  // padding-left: 30em;
}

.container__operacionesMore {
  @include abs.flex(center, flex-start, row, nowrap);
  gap: 5em;
  box-sizing: border-box;
  padding-top: 2em;
}

.title__MoreOperaciones {
  text-align: center;
  font-weight: abs.weight("semibold");
}

// .columnOperacion-L {
//     margin-left: 16em;
// }

.container__OperacionesActualesL {
  background-color: abs.color(abs.$greyColor, "base");
  height: auto;
  min-height: 800px;
  width: 350px;
  border-radius: 15px;
  border: 3px dashed abs.color(abs.$greyColor, "icons");
}

.container__OperacionesTodasR {
  background-color: abs.color(abs.$greyColor, "base");
  height: auto;
  min-height: 800px;
  width: 350px;
  border-radius: 15px;
}

.OperacionesTodasR {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
}

.operacionNormalMore {
  // border-radius: 10px;
  cursor: pointer;
  margin: 1em;
  @include abs.flex(flex-start, center, row, wrap);
  background-color: abs.color(abs.$primaryColor, "operacionMoreB");
  border: none;
  width: auto;
  height: 50px;
  .operacion__text {
    color: abs.color(abs.$primaryColor, "operacionMoreT");
    text-align: center;
    margin: 0 auto;
  }
}

.operacionNormalMoreSelect {
  border: 3px solid #456eff;
}

.operacionOculta {
  display: none;
}

.operacionNormalMoreUnselected {
  // border-radius: 10px;
  cursor: pointer;
  @include abs.flex(flex-start, center, row, wrap);
  width: auto;
  height: 50px;
  margin: 1em;
  background-color: abs.color(abs.$primaryColor, "buttonO");
  border: 1px solid abs.color(abs.$primaryColor, "buttonOText");
  box-sizing: border-box;
  .operacion__text {
    color: abs.color(abs.$primaryColor, "buttonOText");
    text-align: center;
    margin: 0 auto;
    &:hover {
      color: abs.color(abs.$primaryColor, "base");
    }
  }
}

.componentsOperacionesMore {
  padding: 1em 0 1em 0;
  @include abs.flex(space-around, center, row, nowrap);
  width: 50%;
}

//-----------------------PANEL 3
//------------------------Prestacion
.prestacion__container {
  background: white;
  padding-top: 1em;
}

.container__panelPrestacion {
  background: transparent;
  width: 100vw;
}

.prestacion__titleOpem {
  font-weight: abs.weight("semibold");
  padding: 1em 0 1em 1em;
}

// ---------------prestacion data
.data__prestacionOpen {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  width: 100vw;
  margin-top: 1em;
  // border-radius: 20px 20px 0 0;
  height: auto;
  @include abs.flex(space-between, center, row, wrap);
  width: auto;
}

// .container__medicamentos {
//     margin-top: 1em;
//     background: transparent;
//     @include abs.flex(space-between,
//     space-between,
//     row,
//     nowrap);
//     gap: 1em;
//     .medicamentos__columnL {
//         background-color: salmon;
//         background-color: abs.color(abs.$secondaryColor, "background");
//         width: 50%;
//         padding: 1em;
//     }
//     .medicamentos__columnR {
//         width: 50%;
//         padding: 1em;
//         background-color: abs.color(abs.$secondaryColor, "background");
//     }
// }
// ---------------prestaciones
.prestacion__iconsOpen {
  margin-right: 2em;
}

.container-prestacion {
  height: auto;
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  //  width: 100vw;
  box-sizing: border-box;
  padding-left: 1em;
  .container-prestacion1 {
    display: grid;
    // grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
    grid-auto-rows: 2em;
    margin-bottom: 1em;
    gap: 1em;
    width: 100%;
    label {
      white-space: nowrap;
    }
    // input {
    //     height: 15px;
    // }
  }
  .container-prestacion2 {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(auto-fit, minmax(8em, 1fr));
    grid-auto-rows: 2em;
    // gap: 1em;
    width: 100%;
    label {
      white-space: nowrap;
    }
    input {
      height: 15px;
    }
  }
  // .prestacion__autorizacion {
  //     grid-column: 1 / span 2;
  // }
  // .prestacion__cPrestacion {
  //     grid-column: 3 / span 4;
  // }
  #labelCPrestacion {
    width: auto;
  }
  // .prestacion__cantidad {
  //     grid-column: 1 / span 1;
  // }
  // .prestacion__tipo {
  //     grid-column: 2 / span 3;
  // }
  #cantidadPrestacion {
    width: 100%;
    height: 2.5em;
    outline: none;
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 5px;
    border: 0;
    font-size: abs.size("xs");
    padding-left: 1em;
    &:focus {
      outline: none;
    }
  }
  #tipoPrestacion {
    // min-width: 18em;
    height: 2.5em;
    outline: none;
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 5px;
    border: 0;
    font-size: abs.size("xs");
    padding-left: 1em;
    &:focus {
      outline: none;
    }
  }
  #arancelPrestacion {
    width: 100%;
    height: 2.5em;
    outline: none;
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 5px;
    border: 0;
    font-size: abs.size("xs");
    padding-left: 1em;
    &:focus {
      outline: none;
      background-color: abs.color(abs.$greyColor, "base");
      border-radius: 5px;
      border: 0;
      font-size: abs.size("xs");
      padding-left: 1em;
      &:focus {
        outline: none;
      }
    }

    .container-prestacionDatos {
      @include abs.flex(space-between, center, row, nowrap);
    }
    .form-controlN-dashboardP {
      width: auto;
    }
  }

  #finTratamietno {
    width: auto;
    height: 3em;
    outline: none;
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 10px;
    border: 0;
    font-size: abs.size("xs");
    padding-left: 1em;
    &:focus {
      outline: none;
      background-color: abs.color(abs.$greyColor, "base");
      border-radius: 5px;
      border: 0;
      font-size: abs.size("xs");
      padding-left: 1em;
      &:focus {
        outline: none;
      }
    }
  }
  .container-prestacionDatos {
    @include abs.flex(space-between, center, row, nowrap);
  }
  .form-controlN-dashboardP {
    width: auto;
  }
}

.input__codigoPrestaciones {
  width: 100%;
}

// .prestacion__autorizacion {
//     grid-column: 4 / span 2;
// }

.input__codigoPrestaciones {
  width: 100%;
}
.prestacion__autorizacion {
  grid-column: 4 / span 2;
  padding-right: 0.5em;
}
.prestacion__cPrestacion {
  grid-column: 1 / span 3;
  width: 100%;
}

.prestacion__cantidad {
  grid-column: 4 / span 2;
  padding-right: 1em;
}
.prestacion__tipo {
  grid-column: 1 / span 1;
}

.prestacion__tipo {
  grid-column: 1 / span 1;
}

.prestacion__arancel {
  grid-column: 2 / span 2;
}

#cPrestacion {
  width: 95%;
  margin-left: 0.5em;
  font-size: 0.7em;
}

#cFecha {
  width: 95%;
  margin-left: 0.5em;
  font-size: 0.7em;
}

//------------------------cuadro
.prestacion__cuadroCarga {
  margin-right: 5em;
  padding-bottom: 1em;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  margin-left: 1em;
}

.table__prestaciones {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  font-size: 0.8em;
}

.thead__prestaciones {
  background-color: abs.color(abs.$primaryColor, "table");
  .theadTh__prestaciones {
    color: abs.color(abs.$greyColor, "secondary");
    padding: 0.8em 0 0.8em 0.5em;
    text-align: left;
  }
}

.tbodyTr__prestaciones {
  text-align: left;
  height: 35px;
  background-color: abs.color(abs.$greyColor, "directionOne");
  &:nth-child(even) {
    background-color: abs.color(abs.$greyColor, "direction");
  }
  &:nth-child(odd) {
    background-color: abs.color(abs.$greyColor, "directionOne");
  }
}

//------------------------prescriptor
.container-prescriptor {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  //  width: 100vw;
}

.form__prescriptor {
  @include abs.flex(space-between, center, row, nowrap);
  margin: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
  gap: 1em;
}

.prescriptor__title {
  font-weight: abs.weight("semibold");
  padding-left: 1em;
  padding-top: 1em;
}

#butonMore {
  margin: 0;
}
// .text__butonMore{
//   background-color: abs.color(abs.$alertColor,"secondary");
//   color: abs.color(abs.$alertColor,"base");
//   position: absolute;
//   // top: -100px;
//   padding: 1em;
//   border-radius: 10px;
//   font-size: abs.size("xxs");
//   visibility: hidden;
//   opacity: 0;
//   transition: all 500ms ease-out;
//  &::after{
//    content: "";
//    display: block;
//   position: absolute;
//   top: -15px;
//   left: 4px;
//   border-top: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-left: 10px solid transparent;
//   border-bottom: 10px solid abs.color(abs.$alertColor,"secondary");
//  }
// }
.prestacion_butonMore p button:hover {
  position: relative;
}

.prestacion_butonMore p button[data-title]:hover:after {
  content: attr(data-title);
  padding: 4px 8px;
  color: abs.color(abs.$alertColor, "base");
  background-color: abs.color(abs.$alertColor, "secondary");
  font-size: 12px;
  position: absolute;
  // left: 30em;
  top: -0.8em;
  left: -13em;
  // white-space: nowrap;
  z-index: 9999999px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

#butonMore:hover > .text__butonMore {
  opacity: 1;
  visibility: visible;
}

.butonMore {
  border: none;
  background-color: white;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 2em;
}

.butonDelete {
  border: none;
  // background-color: white;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 2em;
  margin-top: 1em;
}

#matriculaPrescriptor,
#provinciaPrescriptor,
#tipoPrescriptor,
#CUITEfector,
#codigoDiagnostico,
#descripcionDiagnostico,
#nombreEfector,
#nCSC {
  font-size: 0.8em;
}

.containerEfDiag {
  display: flex;
  gap: 1rem;
}

.widthContainer {
  width: 50%;
}
//------------------------efector
.container-efector {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  //  width: 100vw;
  margin-bottom: 1em;
}

.title__efector {
  font-weight: abs.weight("semibold");
  padding-left: 1em;
  padding-top: 1em;
}

.form__efector {
  @include abs.flex(space-between, center, row, nowrap);
  margin: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
  gap: 1em;
}

//------------------------acompannante

.title__acompannante {
  font-weight: abs.weight("semibold");
  padding-top: 1em;
}

//------------------------diagnostico
.title__diagnostico {
  font-weight: abs.weight("semibold");
  padding-left: 1em;
  padding-top: 1em;
}

.container-diagnostico {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  //  width: 100vw;
}

.form__diagnostico {
  // @include abs.flex(center,center,row,nowrap);
  // display: inline-flex;
  margin: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(6, auto);
  margin-bottom: 0;
}

.form__acompannante {
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  padding-bottom: 1em;
  box-sizing: border-box;
  gap: 1em;
  display: flex;
  margin-bottom: 0;
  padding-right: 1em;
}

.container-informar-diagnostico {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  //  width: 100vw;
}

.container-rescate-receta {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  //  width: 100vw;
}

.form__informar-diagnostico {
  // @include abs.flex(center,center,row,nowrap);
  // display: inline-flex;
  margin: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
  gap: 1em;
  display: grid;
  grid-template-columns: repeat(1, auto);
  margin-bottom: 0;
}

#codigoDiagnostico {
  width: 100%;
}

#descripcionContainer {
  grid-column: 2 / span 4;
}

//------------------------CSC
.container-CSC {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  width: 100%;
  border-radius: 0 0 10px 10px;
}

.title__CSC {
  font-weight: abs.weight("semibold");
  padding-left: 1em;
  padding-top: 1em;
}

// #nCSC{
//   width: 20em;
// }
.form__CSC {
  margin: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
}

//-----------------------PANEL 4
//------------------------bOTONES
.container__buton {
  background-color: abs.color(abs.$secondaryColor, "background");
  max-width: 100vw;
  // border-radius: 10px;
  @include abs.flex(flex-end, center, row, nowrap);
  padding: 0 1em 0em 0;
  gap: 1em;
  margin-top: 10px;
}

.butonClean {
  background-color: abs.color(abs.$alertColor, "base");
  color: white;
  border: none;
  border-radius: 5px;
  width: 10em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-top: 1em;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  cursor: pointer;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$alertColor, "hover");
  }
  &:active {
    background-color: abs.color(abs.$alertColor, "active");
    transform: translateY(1px);
  }
}

.butonTransaction {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 12em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hoverB");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}

.containerClean {
  margin: 0;
}

.containerClean p button:hover {
  position: relative;
}

.containerClean p button[data-title]:hover:after {
  content: attr(data-title);
  padding: 4px 8px;
  color: abs.color(abs.$alertColor, "base");
  background-color: abs.color(abs.$alertColor, "secondary");
  position: absolute;
  left: 0;
  top: -140%;
  // white-space: nowrap;
  z-index: 20px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

select {
  color: abs.color(abs.$greyColor, "secondary");
}

// .cardN-dashboard {
//     width: auto;
//     height: 100vh;
// }
//----------------DASHBOARD 02D Registracion Diferida - Default
//----------------------prestacion
.container-prestacion02D {
  height: auto;
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  width: 100vw;
  box-sizing: border-box;
  padding-left: 1em;
}

.container-prestacion102D {
  display: grid;
  grid-template-columns: repeat(8, auto);
  grid-template-rows: repeat(2, auto);
  // grid-template-columns: repeat(auto-fill, minmax(8em,1fr));
  margin-bottom: 1em;
  gap: 1em;
  label {
    white-space: nowrap;
  }
  // input {
  //     height: 15px;
  // }
}

.prestacion__cPrestacion02D {
  grid-column: 4 / span 2;
}

.prestacion__cantidad02D {
  grid-column: 1 / span 1;
}

.prestacion__tipo02D {
  grid-column: 2 / span 3;
}

.tipoPrestacion02D {
  width: 40em;
}

//----------------DASHBOARD 04A Anulacion
.container__panelPrestacion {
  height: auto;
  background-color: transparent;
  // max-width: 1500px;
  width: 100vw;
  box-sizing: border-box;
  // border-radius: 10px;
  // margin-bottom: 1em;
  width: auto;
  margin-top: 1em;
}

.container-anulacion {
  height: auto;
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0 0 10px 10px;
}

.formContainer-anulacion {
  @include abs.flex(space-between, center, column, wrap);
  width: 100%;
  // height: 29vh;
  padding: 1em 1em 1em 1em;
  box-sizing: border-box;
}

#nTicket {
  width: auto;
}

//----------------DASHBOARD 02B Prescripción
.container-derivacion {
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  margin-top: 1em;
}

.title__derivacion {
  font-weight: abs.weight("semibold");
  padding-left: 1em;
  padding-top: 1em;
}

.form__derivacion {
  margin: 1em;
  padding-bottom: 1em;
  box-sizing: border-box;
  // @include abs.flex(space-between,center,row,wrap);
  display: grid;
  grid-template-columns: repeat(4, auto);
  gap: 0.5em;
  label {
    white-space: nowrap;
  }
}

.form-infoN-nombreDerivacion {
  grid-column: 1 / span 3;
}

//----------------DASHBOARD 03A Rescate de solicitud
#nAutorizacion {
  width: 80%;
  font-size: 0.7em;
}

#labelnAutorizacion {
  padding-right: 0.2em;
  box-sizing: border-box;
}

//----------------DASHBOARD Realizar Prescripcion
//------------Operacion medicamento
.container__prescripcion {
  @include abs.flex(space-between, center, row, nowrap);
  background-color: abs.color(abs.$secondaryColor, "background");
  // max-width: 1500px;
  // width: 100vw;
  height: 5em;
  // display: unset !important;
}

#buttonPrescripcion {
  width: 15em;
  &:hover {
    background-color: abs.color(abs.$primaryColor, "disabledB");
  }
}

.illustrations__medicamentos {
  margin-left: auto;
}

//------------Operacion medicamento
.medicamentosDeshabilitado {
  color: red;
  background-color: red;

}

.app-prescripcion-medicamento {
  width: 100%;
}
.container__medicamentos {
  margin-top: 1em;
  background: abs.color(abs.$secondaryColor, "background");
  @include abs.flex(space-between, space-between, row, nowrap);
  gap: 1em;
  // border-radius: 10px;
}
#docMedicamento {
  margin: 4rem;
}
//---------Columna Izquierda
.medicamentos__columnL {
  background-color: abs.color(abs.$secondaryColor, "background");
  width: 50%;
  padding: 1em;
  // min-width: 631px;
  // max-width: 631px;
  margin-right: 0em !important;
  // position: absolute;
}

.medicamentos__titleFecha {
  margin-left: 1em;
}

//------fecha
.containerInput__fecha {
  @include abs.flex(space-between, space-between, row, nowrap);
  // margin-left: 1em;
  margin-bottom: 2em;
}

.form-infoN-fechaM {
  @include abs.flex(flex-start, center, row, nowrap);
  width: 100%;
  gap: 0.5em;
  height: abs.size-button("s");
  box-sizing: border-box;
  .form-controlN-fechaM {
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 0.7em;
    -webkit-border-radius: 0.7em;
    -moz-border-radius: 0.7em;
    -ms-border-radius: 0.7em;
    -o-border-radius: 0.7em;
    width: 100%;
    border: none;
    position: relative;
    padding: 10px;
    font-size: abs.size("s");
    margin-right: 0.5em;
    &:focus {
      outline: none;
      box-shadow: 0 0 6px 0 abs.color(abs.$primaryColor, "base");
    }
    &::placeholder {
      color: abs.color(abs.$greyColor, "text");
    }
  }
  label {
    font-weight: abs.weight("light");
    font-size: abs.size("s");
  }
  .calendarFechaM {
    position: relative;
    right: 2.2em;
  }
  .fechaDesde {
    width: 70% !important;
  }
}
#form-infoN-fechaHasta {
  justify-content: flex-end;
}
//------receta
.title__dataPrescripcion {
  font-weight: abs.weight("semibold");
  margin-left: 0.2em;
}

.dataPrescripcion {
  @include abs.flex(space-between, space-between, row, nowrap);
}

.textPrescripcion {
  margin-right: 0;
}

.titlePrescripcion {
  margin: 0.1em;
}

.descriptionPrescripcion {
  padding-top: 0;
  margin-top: 0;
  width: 80%;
  font-weight: abs.weight("semibold");
  font-size: 0.8em;
  padding-right: 5em;
}

.accionPrescripcion {
  margin-left: -8em;
  @include abs.flex(center, center, row, nowrap);
  gap: 0.2em;
}

.cantidadPrescripcion {
  background-color: abs.color(abs.$greyColor, "base");
  border-radius: 0.7em;
  -webkit-border-radius: 0.7em;
  -moz-border-radius: 0.7em;
  -ms-border-radius: 0.7em;
  -o-border-radius: 0.7em;
  width: 40%;
  border: none;
  // position: relative;
  padding: 10px;
  font-size: abs.size("s");
  &:focus {
    outline: none;
    box-shadow: 0 0 6px 0 abs.color(abs.$primaryColor, "base");
  }
  &::placeholder {
    color: abs.color(abs.$greyColor, "text");
  }
}

.buttonMedicament {
  border: none;
  border-radius: 50px;
  background-color: abs.color(abs.$secondaryColor, "base");
}

.detalleIndicacion{
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
}

//------info Prescripcion
.infoPrescripcion p {
  font-size: abs.size("xs");
  font-weight: abs.weight("light");
}

//------firma
.container__firma {
  @include abs.flex(center, center, column, nowrap);
}

.buttonFiramarM {
  background-color: abs.color(abs.$secondaryColor, "base");
  width: 10em;
  height: 2em;
  box-shadow: none;
  border-radius: 5px;
  border: 1px solid abs.color(abs.$primaryColor, "buttonOText");
  color: abs.color(abs.$primaryColor, "buttonOText");
  transition: color 0.5s, background-color 0.2s;
  &:hover {
    color: abs.color(abs.$primaryColor, "base");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "hoverB");
    color: abs.color(abs.$secondaryColor, "base");
  }
}

// .container__medicamentos {
//     background: transparent;
//     @include abs.flex(space-between,
//     space-between,
//     row,
//     nowrap);
//     gap: 1em;
//     margin-top: 1em;
//     .medicamentos__columnL {
//         background-color: abs.color(abs.$secondaryColor, "background");
//         width: 50%;
//         padding: 1em;
//     }
//     .medicamentos__columnR {
//         width: 50%;
//         padding: 1em;
//         background-color: abs.color(abs.$secondaryColor, "background");
//     }
// }
//---------Columna Derecha
.medicamentos__columnR {
  width: 50%;
  padding: 1em;
  background-color: abs.color(abs.$secondaryColor, "background");
  // min-width: 631px;
  // max-width: 631px;
  margin-right: 0em !important;
  // position: absolute;
}

//--------busqueda diagnostico
.rowUpDiagnostico {
  @include abs.flex(space-between, center, row, nowrap);
  gap: 1em;
}

.data__diagnosticoM label {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    z-index: 9999999;
    width: 20px;
  }
  input {
    height: 30px;
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 0.7em;
    -webkit-border-radius: 0.7em;
    -moz-border-radius: 0.7em;
    -ms-border-radius: 0.7em;
    -o-border-radius: 0.7em;
    padding-right: 10em;
    border: none;
    font-size: abs.size("s");
    &:focus {
      outline: none;
      box-shadow: 0 0 6px 0 abs.color(abs.$primaryColor, "base");
    }
    &::placeholder {
      color: abs.color(abs.$greyColor, "text");
    }
  }
}

.labelFormM::before {
  background: {
    size: contain;
    position: center;
    repeat: no-repeat;
  }
}

#label-diagnosticoM::before {
  background-image: url("../assets/design/search.svg");
}

.searchDiagnosticoM {
  width: 10em;
  text-align: center;
  border-radius: 5px;
  height: 2em;
  background-color: abs.color(abs.$greyColor, "base");
  @include abs.flex(center, center, row, nowrap);
  margin-right: 2em;
}

// .optionsDiagnostico {
//     margin-top: 1.5em;
// }

.labelOptionMedicamentos {
  font-weight: abs.weight("light");
  padding-left: 0.5em;
}

.container__optionMedicamento {
  margin: 1em 0 1em 0;
  .justif {
    display: unset !important;
  }
}

//--------datos profesional
.container__datosProfesional {
  // margin: 2em;
  @include abs.flex(space-between, center, row, nowrap);
}

.title__datosProfesional {
  font-weight: abs.weight("semibold");
  font-size: abs.size("s") + 0.2em;
}

.data__datosProfesional {
  font-weight: abs.weight("light");
  font-size: abs.size("s");
  margin-top: 1em;
}

#nMatricula {
  border: none;
  border-radius: 5px;
  width: auto;
}

.agregarMedicamentos{
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

//--------datos beneficiario
.container__beneficiario {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.form__beneficiario {
  .label__beneficiarioEmail {
    font-weight: abs.weight("light");
    padding-right: 1em;
  }
  .container__radioBeneficiario {
    margin-top: 1em;
    // margin-left: 3em;
  }
  .radio__beneficiario {
    color: abs.color(abs.$greyColor, "icons");
  }
  #emailBeneficiario {
    background-color: abs.color(abs.$greyColor, "base");
    border-radius: 0.7em;
    -webkit-border-radius: 0.7em;
    -moz-border-radius: 0.7em;
    -ms-border-radius: 0.7em;
    -o-border-radius: 0.7em;
    border: none;
    // position: relative;
    // padding: 10px;
    width: 25em;
    height: 2em;
    font-size: abs.size("s");
    &:focus {
      outline: none;
      box-shadow: 0 0 6px 0 abs.color(abs.$primaryColor, "base");
    }
    &::placeholder {
      color: abs.color(abs.$greyColor, "text");
    }
  }
}

.protoQuir {
  padding: 15px;
  margin-bottom: 15px;
}

//------------------------------TICKET
.tooltipPTicket {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltipPTicket .tooltiptextPTicket {
  visibility: hidden;
  width: auto;
  // height: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 105%;
}

.tooltipPTicket:hover .tooltiptextPTicket {
  visibility: visible;
}

.filtrosTicket {
  padding-left: 3em;
  padding-right: 3em;
  padding-top: 1em;
}

.procedimientosTicket {
  display: flex;
  justify-content: center;
}

.procedimientoDesc {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 5.5em;
}

.agenda {
  font-size: 15px;
  float: right;
}

.bottonTicket {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  border-radius: 5px;
  width: 7em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hover");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}

.buttonNoVolverAMostrar {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 15em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hoverB");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}

.buttonBuscarTickets {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 6em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hoverB");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}

.buttonImprimirTickets {
  background-color: abs.color(abs.$primaryColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 6em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$primaryColor, "hoverB");
  }
  &:active {
    background-color: abs.color(abs.$primaryColor, "active");
    transform: translateY(1px);
  }
}

.buttonAnularTicket {
  background-color: abs.color(abs.$alertColor, "base");
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 6em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$alertColor, "hover");
  }
  &:active {
    background-color: abs.color(abs.$alertColor, "active");
    transform: translateY(1px);
  }
}

.bottonTicketAlert {
  background-color: abs.color(abs.$alertColor, "base");
  color: white;
  border: none;
  border-radius: 5px;
  width: 7em;
  height: abs.size-button("s");
  font-size: 15px;
  padding: 0.3em;
  align-self: center;
  margin-right: 0;
  transition: border-radius 0.5s;
  -webkit-transition: border-radius 0.5s;
  -moz-transition: border-radius 0.5s;
  -ms-transition: border-radius 0.5s;
  -o-transition: border-radius 0.5s;
  &:hover {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: abs.color(abs.$alertColor, "hover");
  }
  &:active {
    background-color: abs.color(abs.$alertColor, "active");
    transform: translateY(1px);
  }
}

.logoNuvalidAzul {
  filter: invert(49%) sepia(86%) saturate(5313%) hue-rotate(219deg)
    brightness(103%) contrast(101%);
}

.cscLogoModal {
  display: flex;
  justify-content: flex-end;
}

.sugerenciasDatosPersonales {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.sugerenciasM {
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
}

.container__enviarS {
  background-color: abs.color(abs.$secondaryColor, "background");
  max-width: 100vw;
  // border-radius: 10px;
  @include abs.flex(flex-end, center, row, nowrap);
  padding: 0 1em 0em 0;
  gap: 1em;
  margin-top: 10px;
  height: 60px;
}

.buscarMedicamentos{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px;
  width: auto;
}

@media (max-width: 1370px) {
  .container__panel1 {
    .container__prestador {
      .illustrations-prestador {
        display: none;
      }
    }
  }

  //--------------------Medicamentos

  .container__medicamentos {
    flex-wrap: wrap;
    justify-content: center;
  }

  .medicamentos__columnL,
  .medicamentos__columnR {
    width: 100%;
  }
  .illustrations__medicamentos {
    display: none;
  }
}

@media (max-width: 1195px) {
  //---------------Prestador
  .illustrations-prestador {
    display: none;
  }

  // .container__panel1{
  //     .reminder_afiliado{
  //         margin-left: 1em;
  //         margin-top: 1.5em;

  //     }
  // }
  .container__panel1 {
    .reminder_afiliado {
      margin-left: 1em;
      margin-top: 1.5em;
    }
    .container__afiliado {
      .containerInputA {
        margin-left: 1em;
        margin-top: 1em;
      }
    }
    .reminder__textAfiliado {
      width: 100%;
    }
    .container__prestador {
      margin-top: 0;
      .prestador-columnR {
        .prestador-form {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 0;
          #optionPrestadores {
            margin-left: 1em;
          }
        }
        .reminder__warningPrestador {
          margin-left: 0;
        }
        .reminder__textPrestador {
          width: 80%;
        }
        .reminderPrestador {
          margin-left: 1em;
        }
      }
    }
  }
  .tittle__dashboard {
    margin-top: 0;
  }
  .containerInputA {
    margin-left: 0;
  }

  .reminder__alertA {
    margin-left: 0;
    margin-top: 1em;
  }
  //--------------Operacion
  .container__ilustrationOperation {
    display: none;
  }

  .containerEfDiag {
    flex-direction: column;
    gap: 0;
  }

  .widthContainer {
    width: 100%;
  }
}

@media (max-width: 945px) {
  //-----------Generales
  .cardN {
    // margin: 0 1em 0 1em;
    width: 99%;
  }
  .card__columnR {
    width: 100%;
    padding: 2em 2em 0 2em;
  }
  .form-textN {
    font-size: 0.8em;
    width: 90%;
  }
  .form-infoN {
    font-size: 0.8em;
  }
  .form-controlN {
    width: 80%;
  }
  .reminder {
    margin-left: 1em;
    .reminder__text {
      font-size: 0.7em;
      width: 95%;
    }
  }
  .components {
    margin: 5% 0 5% 0;
  }
  .components__imgDoctora {
    display: none;
  }
  //--------------------Directions
  .list-directions {
    margin: 0 1em 0 1em;
  }

  //----------------------Contactanos
  .form-contact__image {
    position: static;
    margin-left: 38em;
  }
  //-----------------------LOGIN
  #btnentrar {
    margin-left: 50% !important;
  }

  .cardN-login {
    margin-top: 10em;
  }
  //--------------------DASHBOARD
  .containerN-dashboard {
    margin: 0;
  }

  #cardNRestablecer {
    width: auto;
    margin: 0 0 0 1em;
  }
  .containerInputA {
    width: 50%;
  }

  //--------------------More Operaciones
  .container__operacionesMore {
    gap: 1em;
  }
}

// @media (max-width: 845px ) {

// .cardN-login {
//    margin: 0 0.2em 0 0.2em ;
//     width: 100vw;
//     max-width: 100vw;
//     // height: 100vw;
//     // max-height: 100vw;

// }
// }

@media (max-width: 845px) {
  .cardN-login {
    // margin: 0 0.2em 0 0.2em;
    width: 100vw;
    max-width: 100vw;
    // height: 100vw;
    //     max-height: 100vw;
    margin-top: 10em;
    margin: 0;
  }
  // #card__columnR-login,#card__columnR-login, .card__columnL, .card__columnR{
  //     border-radius: 0;
  // }
  .illustrations .img__login {
    left: 30%;
    width: 19em;
  }
  #button-box {
    top: 42%;
  }
  .input-group {
    margin-left: 20%;
    margin-top: 5%;
  }

  //---------------Contactanos
  #ilustrations-contactWoman {
    display: none;
  }

  .form-dataN {
    margin-top: 3em;
  }

  .form-contact__image {
    margin-left: 20em;
  }

  //----------------Dashboard
  .containerN-dashboard {
    margin: 0;
  }

  //------------------More operacion
  .container__OperacionesActualesL,
  .container__OperacionesTodasR {
    width: 300px;
  }
}

@media (max-height: 768px) and (max-width: 1366px) {

  .containerNNav {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .cardN-loginP,
  #cardNInst {
    margin-top: 0;
  }

  .card__columnL {
    background-color: abs.color(abs.$primaryColor, "base");
    height: auto;
    @include abs.flex(space-between, center, column, wrap);
    // @include abs.borderL();
    width: 10em;
    max-width: 5em;
    min-width: 5em;
  }

  .card__logoNuvalidC,
  .card__phoneC {
    margin: 1em 0px 1em 0px;
  }

  .container__panel1 {
    max-width: 100%;
    // margin-top: 1em;
    background-color: transparent;
    //----------------------Financiador
    .container__financiador {
      background-color: abs.color(abs.$secondaryColor, "background");
      border-radius: 20px 20px 0 0;
      height: auto;
      max-height: 400px;
      // padding: 0.5em 0em 0 1em;
      padding: 0 0em 0 1em;
      .title_containerF {
        @include abs.flex(space-between, center, row, nowrap);
        .arrowDownPanel {
          margin-right: 2em;
        }
      }
      .containerFinanciers-img {
        @include abs.flex(flex-start, flex-start, row, wrap);
        height: 9.5em;
        overflow-y: scroll;
        gap: 0.8em;
        width: 100%;
        .financier {
          background-color: abs.color(abs.$greyColor, "base");
          width: 17em;
          height: 8em;
          margin: 0;
          border-radius: 10px;
          cursor: pointer;
          @include abs.flex(center, center, row, nowrap);
          img {
            width: 16rem;
            height: auto;
          }
        }
      }
    }
    .financier-notChecked {
      display: none;
    }
    .financier-checked {
      position: relative;
      top: -2.8em;
      left: -1.2em;
    }
    .financierSelected {
      box-sizing: border-box;
      border: 3px solid abs.color(abs.$sucessColor, "base");
    }
    //----------Afiliado
    .container__afiliado {
      background-color: abs.color(abs.$secondaryColor, "background");
      padding: 0.5em 0em 1em 1em;
      // margin-top: 1em;
      .containerInputA {
        width: 80%;
        margin-left: 3em;
        label {
          margin-right: 1em;
        }
        .labelAgenda {
          margin-right: 2.8em !important;
        }
        #input-credencial {
          width: 80%;
          height: 2em;
        }
      }
    }

    .reminder_afiliado {
      margin-left: 15em;
      margin-top: 0.2em;
    }
    .reminder__textAfiliado {
      width: fit-content;
      margin-left: 0;
      margin-top: 0.5em;
    }

    .reminder__textAlertAfiliado {
      margin-left: 0;
    }
    //----------Prestador
    .container__prestador {
      // margin-top: 1em;
      background-color: abs.color(abs.$secondaryColor, "background");
      border-radius: 0 0 20px 20px;
      padding: 0 0 0 1em;
      @include abs.flex(space-between, center, row, wrap);
      .prestador-columnR {
        @include abs.flex(center, flex-start, column, wrap);
        .prestador-form {
          @include abs.flex(center, center, row, wrap);
          gap: 1em;
          #optionPrestadores {
            width: 35em;
            height: 2.5em;
            outline: none;
            background-color: abs.color(abs.$greyColor, "base");
            border-radius: 5px;
            border: 0;
            font-size: abs.size("xs");
            padding-left: 1em;
            &:focus {
              outline: none;
            }
          }
        }
        .reminderPrestador {
          width: 80%;
          margin-left: 5em;
          margin-bottom: 1em;
        }
        .reminder__warningPrestador {
          margin: 0;
          margin-left: 10.5em;
        }
        .reminder__textPrestador {
          // margin-top: 0;
          width: 60%;
          // margin-bottom: 0;
          margin: 0;
          .reminder__PrestadorB {
            font-weight: abs.weight("semibold");
          }
        }
      }
      .illustrations-prestador {
        margin-bottom: 0em;
        margin-left: 2em;
        img {
          margin-bottom: -0.3em;
        }
      }
    }
  }
}

@media (min-height: 600px) and (min-width: 600px) {

  .containerNNav {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }

  .cardN-loginP,
  #cardNInst {
    margin-top: 0;
  }

  // .card__columnL {
  //   background-color: abs.color(abs.$primaryColor, "base");
  //   height: auto;
  //   @include abs.flex(space-between, center, column, wrap);
  //   // @include abs.borderL();
  //   width: 10em;
  //   max-width: 5em;
  //   min-width: 5em;
  // }

  .card__logoNuvalidC,
  .card__phoneC {
    margin: 1em 0px 1em 0px;
  }

}
