@use "colors" as col;
@use "fonts" as fon;

//----------- mixin de flex

@mixin flex($justify-contenet, $align-items, $flex-direction, $flex-wrap) {
  display: flex;
  justify-content: $justify-contenet;
  align-items: $align-items;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
}

@mixin borderL() {
  border-radius: 1em 0px 0px 1em;
  -webkit-border-radius: 1em 0px 0px 1em;
  -moz-border-radius: 1em 0px 0px 1em;
  -ms-border-radius: 1em 0px 0px 1em;
  -o-border-radius: 1em 0px 0px 1em;
}

@mixin borderR() {
  border-radius: 0px 1em 1em 0px;
  -webkit-border-radius: 0px 1em 1em 0px;
  -moz-border-radius: 0px 1em 1em 0px;
  -ms-border-radius: 0px 1em 1em 0px;
  -o-border-radius: 0px 1em 1em 0px;
}

@mixin boxShadow {
  box-shadow: 29px 22px 70px -40px rgba(0, 0, 0, 0.31);
  -webkit-box-shadow: 29px 22px 70px -40px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 29px 22px 70px -40px rgba(0, 0, 0, 0.31);
}
